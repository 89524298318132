<template>
  <q-btn-dropdown
    v-if="dropDownMode"
    dropdown-icon="share"
    :model-value="false"
  >
    <q-list>
      <q-item clickable v-close-popup @click="shareOnFacebook">
        <q-item-section avatar>
          <q-avatar icon="fab fa-facebook" color="grey-2" text-color="dark" />
        </q-item-section>
        <q-item-section>
          <q-item-label>{{ $t("global.facebook") }}</q-item-label>
        </q-item-section>
      </q-item>
      <q-item clickable v-close-popup @click="shareOnWhatsapp">
        <q-item-section avatar>
          <q-avatar icon="fab fa-whatsapp" color="grey-2" text-color="dark" />
        </q-item-section>
        <q-item-section>
          <q-item-label>{{ $t("global.whatsapp") }}</q-item-label>
        </q-item-section>
      </q-item>
      <q-item clickable v-close-popup @click="shareOnTwitter">
        <q-item-section avatar>
          <q-avatar icon="fab fa-twitter" color="grey-2" text-color="dark" />
        </q-item-section>
        <q-item-section>
          <q-item-label>{{ $t("global.twitter") }}</q-item-label>
        </q-item-section>
      </q-item>
      <q-item clickable v-close-popup @click="copyOnlyLinkInClipboard">
        <q-item-section avatar>
          <q-avatar icon="content_copy" color="grey-2" text-color="dark" />
        </q-item-section>
        <q-item-section>
          <q-item-label>{{ $t("global.copy_link") }}</q-item-label>
        </q-item-section>
      </q-item>
      <q-item clickable v-close-popup @click="copyInClipboard">
        <q-item-section avatar>
          <q-avatar icon="format_quote" color="grey-2" text-color="dark" />
        </q-item-section>
        <q-item-section>
          <q-item-label>{{ $t("global.copy_link_description") }}</q-item-label>
        </q-item-section>
      </q-item>
    </q-list>
  </q-btn-dropdown>
  <div v-else class="ic-share row width-fit-content-flex justify-around">
    <button
      @click="shareOnFacebook"
      v-ripple.center:purple
      class="width-fit-content-flex row items-center justify-center"
      :style="{ color: color }"
    >
      <i class="fab fa-facebook" style="font-size: 19px"></i>
    </button>
    <button
      @click="shareOnWhatsapp"
      v-ripple.center:purple
      class="width-fit-content-flex row items-center justify-center"
      :style="{ color: color }"
    >
    <whatsappIcon :fill="color" size="26px"/>
    </button>
    <button
      @click="shareOnTwitter"
      v-ripple.center:purple
      class="width-fit-content-flex row items-center justify-center"
      :style="{ color: color }"
    >
      <twitterIcon :fill="color" size="19.35px"/>
    </button>
    <q-btn-dropdown
      v-if="canShowLinkAndDescription()"
      v-ripple.center:purple
      class="width-fit-content-flex row items-center justify-center"
      :text-color="color"
      icon="fas fa-link"
    >
      <q-list>
        <q-item clickable v-close-popup @click="copyOnlyLinkInClipboard">
          <q-item-section>
            <q-item-label>{{ $t("global.copy_link") }}</q-item-label>
          </q-item-section>
          <q-item-section side>
            <q-icon name="link" color="dark" />
          </q-item-section>
        </q-item>
        <q-item clickable v-close-popup @click="copyInClipboard">
          <q-item-section>
            <q-item-label>{{
              $t("global.copy_link_description")
            }}</q-item-label>
          </q-item-section>
          <q-item-section side>
            <q-icon name="format_quote" color="dark" />
          </q-item-section>
        </q-item>
      </q-list>
    </q-btn-dropdown>
    <button
      v-else-if="!hideCopyLink"
      @click="
        () => (hideOnlyLinkCopy ? copyInClipboard() : copyOnlyLinkInClipboard())
      "
      v-ripple.center:purple
      class="width-fit-content-flex"
      :style="{ color: color }"
    >
      <i class="fas fa-copy" style="font-size: 19px"></i>
    </button>
  </div>
</template>

<script>
import {
  callToWhatsApp,
  callToFacebook,
  callToTwitter,
} from "@/shared/helpers/networks";
import { notifyError, notifyInfo } from "@/shared/helpers/notify";
import { copyToClipboard } from "quasar";
import { filter } from "lodash";
import whatsappIcon from '@/assets/icons/components/whatsapp.vue'
import twitterIcon from '@/assets/icons/components/twitter.vue'

export default {
  name: "BarShare",

  components: { whatsappIcon, twitterIcon },

  props: {
    dropDownMode: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    link: {
      type: String,
    },
    color: {
      type: String,
      default: "black",
    },
    hideOnlyLinkCopy: {
      default: false,
    },
    hideCopyLink: {
      default: false
    }
  },

  setup(props) {
    const canShowLinkAndDescription = () => {
        return (
          props.hideOnlyLinkCopy === false &&
          filter([props.title, props.description]).length > 0
        );
      },
      shareOnWhatsapp = () => {
        let text = filter([
          props.title ? `${props.title}` : null,
          props.link,
        ]).join("\n");
        callToWhatsApp(text);
      },
      shareOnFacebook = () => {
        let text = filter([props.title]).join("\n");
        callToFacebook(text, props.link);
      },
      shareOnTwitter = () => {
        let text = filter([props.title]).join("\n");
        callToTwitter(text, props.link);
      },
      copyAction = (text) => {
        copyToClipboard(text)
          .then(() => {
            notifyInfo("global.copied.success");
          })
          .catch(() => {
            notifyError("global.copied.error");
          });
      },
      copyInClipboard = () => {
        let text = filter([props.title]).join("\n");
        copyAction(text);
      },
      copyOnlyLinkInClipboard = () => {
        copyAction(props.link);
      };

    return {
      shareOnWhatsapp,
      shareOnFacebook,
      shareOnTwitter,
      copyInClipboard,
      canShowLinkAndDescription,
      copyOnlyLinkInClipboard,
    };
  },
};
</script>

<style lang="scss" scoped>
.ic-share button:not(.q-btn-dropdown) {
  cursor: pointer;
  position: relative;
  border: none;
  background-color: transparent;
  font-size: 1.5em;
}
.ic-share .q-btn-dropdown {
  background-color: transparent !important;
  width: 100%;
  padding: 0;
  &:before {
    box-shadow: none !important;
  }
}

.mobile {
  .ic-share button:not(.q-btn-dropdown) {
    font-size: 1.2em;
  }
}
</style>
<style>
.ic-share .q-btn-dropdown i {
  font-size: 1.5em;
}

.mobile .ic-share .q-btn-dropdown i {
  font-size: 1.2em;
}
</style>
